import * as React from "react"
import { cx } from "../../utils/all";

export default function Label(props) {
  const color = {
    slate: "text-slate-500",
    orange: "text-orange-400",
    red: "text-red-600",
    amber: "text-amber-700",
    yellow: "text-yellow-500",
    lime: "text-lime-500",
    green: "text-green-500",
    emerald: "text-emerald-700",
    blue: "text-blue-600",
    cyan: "text-cyan-400",
    purple: "text-purple-600",
    fuchsia: "text-purple-400",
    pink: "text-pink-600",
    rose: "text-pink-400"
  };
  return (
    <span
      className={cx(
        "inline-block mt-5 text-xs font-medium tracking-wider uppercase",
        color[props.color] || color.pink
      )}>
      {props.children}
    </span>
  );
}
