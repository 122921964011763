import * as React from "react";
// import { Link } from "gatsby";
import Label from "./label";
import TagsDatabase from "../../constants/TagsDatabase";

const CategoryLabel = ({ categories }) => {
  return (
    <div className="flex gap-3">
      {categories?.length &&
        categories.slice(0).map((category, index) => (
          // <Link to="#" key={index}>
          <Label color={TagsDatabase[category].color} key={index}>{TagsDatabase[category].title}</Label>
          // </Link>
        ))}
    </div>
  );
}

export default CategoryLabel;