const TagsDatabase = {
    homeAutomation: {
        title: 'Home Automation',
        color: 'blue',
        underlineClass: 'from-blue-200 to-blue-100',
        underlineClassDark: 'dark:from-blue-900'
    },
    data: {
        title: 'Data',
        color: 'lime',
        underlineClass: 'from-lime-200 to-lime-100',
        underlineClassDark: 'dark:from-lime-900'
    },
    programming: {
        title: 'Programming',
        color: 'orange',
        underlineClass: 'from-orange-200 to-orange-100',
        underlineClassDark: 'dark:from-orange-900'
    },
    javaScript: {
        title: 'JavaScript',
        color: 'orange',
        underlineClass: 'from-orange-200 to-orange-100',
        underlineClassDark: 'dark:from-orange-900'
    },
    diy: {
        title: 'DIY',
        color: 'amber',
        underlineClass: 'from-amber-200 to-amber-100',
        underlineClassDark: 'dark:from-amber-900'
    },
    '3dPrinting': {
        title: '3D Printing',
        color: 'red',
        underlineClass: 'from-red-200 to-red-100',
        underlineClassDark: 'dark:from-red-900'
    },
    ble: {
        title: 'Bluetooth',
        color: 'slate',
        underlineClass: 'from-slate-200 to-slate-100',
        underlineClassDark: 'dark:from-slate-600'
    },
    irrigation: {
        title: 'Irrigation',
        color: 'green',
        underlineClass: 'from-green-200 to-green-100',
        underlineClassDark: 'dark:from-green-900'
    },
    coasters: {
        title: 'Coasters',
        color: 'yellow',
        underlineClass: 'from-yellow-200 to-yellow-100',
        underlineClassDark: 'dark:from-yellow-800'
    }
};

export default TagsDatabase;